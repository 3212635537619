<template >
  <div class="loginPage">
    <div class="container">
      <div class="forms-container">
        <div class="signin-signup">
          <form action="#" class="sign-in-form">
            <h2 class="title">Sign in</h2>
            <div v-if="local">
              prefix?
              <input type="checkbox" name="" id="" v-model="prefix">
            </div>

            <div class="input-field">
              <i class="fas fa-user"></i>
              <input type="text" placeholder="Username" v-model="userLogin"/>
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="Password" name="" id="" v-model="passwordLogin">
            </div>
            <router-link to="/forgot"  style="text-align: left; max-with:380px;">Forgot Password</router-link>
            <button class="btn-l solid" type="button" :style="'background: '+colors[1]+';'" v-on:click="loginUser(userLogin.toLowerCase(),passwordLogin)"> Log In </button>
            <!--p class="social-text">Or Sign in with social platforms</!--p>
            <div-- class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div-->
          </form>
          <form v-if="!$router.currentRoute.value.query.data" action="#" class="sign-up-form">
            <h2 class="title">Guest Sign Up </h2>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="text" placeholder="Firts Name" v-model="firtNameSingUp" />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="text" placeholder="Last Name" v-model="lastNameSingUp" />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="text" placeholder="Phone" v-model="phoneSingUp" />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="email" placeholder="Email" v-model="userSingUp" />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="Password" v-model="passwordSingUp" />
            </div>
            <button class="btn-l solid" type="button" v-on:click="singUpUser"> Sign up </button>
            <!--p class="social-text">Or Sign up with social platforms</!--p>
            <div-- class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div-->
          </form>
          <form v-else action="#" class="sign-up-form">
            <h2 class="title">Client Sign up</h2>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="email" placeholder="Email" v-model="userSingUp" />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="Password" v-model="passwordSingUp" />
            </div>
            <button class="btn-l solid" type="button" v-on:click="singUpUser"> Sign up </button>
            <!--p class="social-text">Or Sign up with social platforms</!--p>
            <div-- class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div-->
          </form>
        </div>
      </div>
      <div class="panels-container">
        <div class="panel left-panel">
          <div class="content">
            <img style="    width: 100%;  max-width: 300px;  max-height: 200px;" :src="logo" alt="logo not found">
          </div>
          <img src="../../assets/icons/log.svg" class="image" alt="" />
        </div>
        <div class="panel right-panel">
          <div class="content">
            <h3>One of us ?</h3>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
              laboriosam ad deleniti.
            </p>
            <button class="btn-l transparent" id="sign-in-btn" v-on:click="sign_in_btn()">
              Sign in
            </button>
          </div>
          <img src="../../assets/icons/register.svg" class="image" alt="" />
        </div>
      </div>
    </div>
  </div>
  <a href="/login"></a>
</template>

<script>
import { smd_getRecordObj, smd_getMyOrganization, smd_listEntityObj, smd_listUserGroups  } from '../../graphql/queries'
import { smd_clientSignup } from '../../graphql/mutations'
import { API, Auth } from "aws-amplify";
//Component
import PaperDisplayer from '../../components/papers/PaperDisplayer.vue'
import UiButton from '../../components/ui/UiButton.vue'

//Tools
import Vuex from "vuex";
import Swal from 'sweetalert2'
import {orderColors} from '../../store/tools.js'


export default {
  components: { 
    PaperDisplayer,
    UiButton,
  },
  name: "Login",
  data() {
    return {
      local:false,
      userLogin: "",
      passwordLogin: "",
      userSingUp: "",
      passwordSingUp: "",
      signedIn: false,
      apiRequest: false,
      firtNameSingUp: null,
      lastNameSingUp: null,
      phoneSingUp: null,
      prefix:false,
      colors:[],
      logo:''
    };
  },
  created() {
    if (location.host == 'localhost:8080' ) {
      this.local = true
    }
    console.log('Created');
    if (this.$router.currentRoute.value.query.mode == 0) {
      setTimeout(() => {
        this.sign_up_btn()
      }, 500);
    }
    this.loading('show')
    
    //
    this.setUserWeb()
    setTimeout(() => {   
      try {
        
        console.log('colores:',this.colors);
        this.colors = orderColors(this.publicOrganizationData.colorSeriesA)
        this.logo = this.publicOrganizationData.logoWhite[0].url
        document.getElementById('title').innerText = 'SmartDash'
        document.getElementById('favicon').href = JSON.parse(this.organizationData.favicon.value)[0].url
        this.loading('hidden')
      } catch (error) {
        console.log(error);
        this.loading('hidden')
      }
      console.log(this.$router.currentRoute.value.query);
      if (this.$router.currentRoute.value.query.data == 'dashboard') {
        this.loginUser(this.$router.currentRoute.value.query.user, this.$router.currentRoute.value.query.pass)
      }
    }, 1500);

  },
  methods: {
    ...Vuex.mapMutations(["setIdentityId","SetOrganizationID", "SetUser", "SetRows", "setDataUser",'setAttUser', 'setPublicOrganization','setUserPermissions' ,'setPublicOrganizationData','SetOrganizationData','setClasses','SetPermissions']),
    ...Vuex.mapActions(['getPublicOrganization','getRecordLists','getEntities','getProducts']),
    //Tools
    loading(action){
      if (action == 'show') {
        document.getElementById("loading").classList.add('show')
        document.getElementById("body").classList.add("noScroll")
      }else{
        document.getElementById("loading").classList.remove('show')
        document.getElementById("body").classList.remove("noScroll")
      }
    },
    async setUserWeb(){
      console.log('>>> Usuario: '+this.user);
        Auth.signIn('smd_webuser', '383@JDU45ss#')
        .then((user) => { 
          //Clear Global Variables
          this.SetOrganizationID("Not authorized")
          this.SetOrganizationData("Not authorized")
          this.setPublicOrganization(undefined)
          this.setPublicOrganizationData(undefined)
          //console.clear()
          ///-----

          this.getPublicOrganization()
          this.loading('hidden') 
        })
        .catch((err) => console.log(err))
    },
    async loginUser(username, pass) {
      let inputUser= username
      this.apiRequest = true;
      console.log(this.organizationData['login.version']);
      if (this.organizationData['login.version']== '2') {
        username = this.publicOrganization.replace('ORG#','').toLowerCase()+'.'+username
      } 
      if (location.host == 'localhost:8080') {
        username = username.replace( this.publicOrganization.replace('ORG#','').toLowerCase()+'.','')
        if (this.prefix) {
          username = this.publicOrganization.replace('ORG#','').toLowerCase()+'.'+username
        }
      }
      console.log(username,  pass);
      Auth.signIn(username, pass)
      .then((user) => {
          console.clear()
          console.log("%cThe BizplanEasy team welcomes you!!!", "font-size:20px;");
          console.log(user);
          this.SetOrganizationID(user.attributes["custom:organizationID"]);
          //this.getOrganizationData(user.attributes["custom:organizationID"]) //Before Integrate Alias
          this.SetUser(user.username);
          this.getEntities()
          this.setUserPermissions(user.signInUserSession.accessToken.payload['cognito:groups'])
          this.apiRequest = false;
          if (user.attributes["custom:organizationID"] == this.publicOrganization   || window.location.host.replace('www.','').toLowerCase() == 'localhost:8080' ||  window.location.host.replace('www.','').toLowerCase() == '192.168.1.2:8080' ||  window.location.host.replace('www.','').toLowerCase() == 'test.bizplaneasy.com') {
            if (!this.$router.currentRoute.value.query.data || this.$router.currentRoute.value.query.data == 'dashboard') {
              this.getUser()
              this.setAttUser(user.attributes)
              this.getPermissions()
              this.getProducts()
              setTimeout(() => {
                  this.$router.push({
                    path: "/dashboard",
                    query:{name:'dash'}
                  });
              }, 1000);
            }else{
              console.log('else');
              switch (this.$router.currentRoute.value.query.data) {
                case 'customerdash':
                  this.setAttUser(user.attributes)
                  this.getPermissions()
                  this.getProducts()
                  setTimeout(() => {
                      this.$router.push({
                        //path: "/dashboard",
                        //query:{name:'dash'}
                        path: "/customerdash",
                        query:{name:'CustomerDash'}
                      });
                  }, 1000);
                break;
                case 'questionnaires':
                  this.getUser()
                  this.$router.push({
                    path: "/"+this.$router.currentRoute.value.query.data,
                    query:{data:this.currentItem}
                  });
                break;
                default:
                  this.getUser(true)
                  this.setAttUser(user.attributes)
                  this.$router.push({
                    path: "/"+this.$router.currentRoute.value.query.data,
                  });
                break;
              }
            }
          }else{
            console.log(user.username.toUpperCase()+' of '+user.attributes["custom:organizationID"] +' - Alias: '+this.publicOrganization );
            Swal.fire({
              icon: 'error',
              title: 'Unauthorized User' ,
              text: 'Contact your provider',
              //footer: 'f_GetItem = smd_getSMLItem PK:  '+ pk + ', SK: ' +sk,
            })
          }
        })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong' ,
          text: err,
          //footer: 'f_GetItem = smd_getSMLItem PK:  '+ pk + ', SK: ' +sk,
        })
        console.log(err)
      });
    },
    async singUpUser() {
      //PK: ORG#BIZPLANEASY
      //SK: CON#0a03d6a0-           7859-460e-b37c-836e0c7bf803
      //pepe21@bizplaneasy.com
      try {
        console.log(this.$router.currentRoute.value.query.data);
        let pushData = await API.graphql({
          query: smd_clientSignup,
          variables:{
            email: this.userSingUp,
            password: this.passwordSingUp,
            contactPK: this.publicOrganization,
            contactSK: this.$router.currentRoute.value.query.data,
            firstName: this.firtNameSingUp,
            lastName: this.lastNameSingUp,
            phoneNumber: this.phoneSingUp,
          }
        })
        pushData = pushData.data.smd_clientSignup
        console.log(pushData);
        if (pushData.smd_response.statusCode != '200') {
          Swal.fire({
            icon: 'warning',
            title: 'Code: ' + pushData.smd_response.statusCode,
            text: pushData.smd_response.userMessage,
            footer: pushData.smd_response.message ,
          })
        }else{
            this.loginUser(this.userSingUp, this.passwordSingUp)
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong' ,
          text: JSON.stringify(error),
          footer: 'smd_contactSignup',
        })
      }
    },
    sign_in_btn() {
      const container = document.querySelector(".container");
      //console.log("mode sing-in");
      container.classList.remove("sign-up-mode");
    },
    sign_up_btn() {
      const container = document.querySelector(".container");
      //console.log("mode sing-up");
      container.classList.add("sign-up-mode");
    },
    async getUser(client){
      try {
        const session = await Auth.currentUserCredentials();
        this.setIdentityId(session.identityId)
        if (client == true) {
          console.log('Entro a cliente');
          this.setDataUser([])
          return
        }
        let pullData = ''
        console.log({ PK: this.organizationID, SK: 'USR#'+this.userLogin.toLowerCase()} );
        pullData = await API.graphql({
          query: smd_getRecordObj,
          variables: {
            PK: this.organizationID,
            SK: 'USR#'+this.userLogin.toLowerCase(),
          },
        });
        pullData = pullData.data.smd_getRecordObj
        console.log(pullData);
        let dataUser = JSON.parse(pullData.data)
        console.log("--> Data User: ", dataUser);
        
        for (const key in dataUser) {
          try {
            if (dataUser[key].attributeType == 'L' ) {
              if (dataUser[key].value != '') {
                dataUser[key].value = JSON.parse(dataUser[key].value)
              }else{
                dataUser[key].value = "[]"
              }
            }
          } catch (error) {
            //console.error(error);
          }
        }
        this.setDataUser(dataUser)
        
      } catch (error) {
        console.log(error);
      }
    },
    async getOrganizationData() {
      try {        
        let pullData = await API.graphql({
          query: smd_getMyOrganization,
        });
        pullData = pullData.data.smd_getMyOrganization;
        console.log(pullData);
        let organization = {}
        for (let index = 0; index < pullData.attributes.length; index++) {
          let att = pullData.attributes[index];
          if (att.attributeType == 'L') {
            organization[att.attributeName] = JSON.parse(att.attributeValue)
          }else{
            organization[att.attributeName] = att.attributeValue  
          }
          switch (att.attributeName) {
            case "organization.color.main":
              this.background = att.attributeValue;
              
            break;
          }
        }
        this.SetOrganizationData(organization)
        this.getClass();
        //this.getRecordLists('PRO',)
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!' ,
          text: error,
          footer: 'smd_getMyOrganization'
        })
      }
    },
    async getClass(){
      //console.log('smd_listEntity: '+this.organizationID);
      let classes = [];
      let cssCode = '';
      let pullData = await API.graphql({
        query: smd_listEntityObj,
        variables: {
          active: '1' ,
          PK: '#SMARTDASH',
          pageSize: 0 ,
          shortEntity: 'CSS',
        },
      });
      pullData = pullData.data.smd_listEntityObj
      for (let index = 0; index < JSON.parse(pullData.data).length; index++) {
        const element  = JSON.parse(pullData.data)[index]
        classes.push({
          name: element.attributes.name.attributeValue ,
          component: element.attributes.elementType.attributeValue,
          code: unescape(element.attributes.code.attributeValue)
        })
      }
      this.setClasses(classes)
      classes.forEach(element => {
        cssCode = cssCode +' '+element.code
      });
      //console.log(classes);
      //console.log(cssCode);
      //Set stley in head <style>
      document.head.insertAdjacentHTML("beforeend", `<style>${cssCode}</style>`)

    },
    async getPermissions() {
      try {
        const w_pull = await API.graphql({
          query: smd_listUserGroups,
        });
        let publicP = [{id: 'Public', value: 'Public'}]
        let getPermissions = JSON.parse(w_pull.data.smd_listUserGroups.data)
        let permissions = []//publicP.concat(getPermissions);
        let perm = [ "Admin", "Editor","Superadmin","Public"]
        if (this.organizationData.securityGroups && this.organizationData.securityGroups != '') {
          perm = JSON.parse(this.organizationData.securityGroups).concat([ "Admin", "Editor","Superadmin","Public"]);
        }
        for (let index = 0; index < perm.length; index++) {
          const element = perm[index];
          permissions.push({id: element, value: element})
        }
        if (this.userPermissions.includes('Superadmin')) {
          permissions = publicP.concat(getPermissions);
        }
        this.SetPermissions(permissions);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'organizationData.securityGroups not Working' ,
          text: 'Contact your provider',
          //footer: 'f_GetItem = smd_getSMLItem PK:  '+ pk + ', SK: ' +sk,
        })
        console.log(error);
      }
    },
  },
  computed:{
    ...Vuex.mapState(["organizationID",'organizationData', 'publicOrganization','user','publicOrganization', 'publicOrganizationData','currentItem','userPermissions'])
  }
};
</script>


<style lang="less">

</style>
<style >


body,
input {
  font-family: "Poppins", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
  max-width: 100%;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 0.3s 0.1s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.1s 0.1s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 0;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.2s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 0;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btn-l {
  width: 150px;
  background-color: #5995fd;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn-l:hover {
  background-color: #4d84e2;
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(135deg, v-bind(colors[2]) 0%, v-bind(colors[1]) 100%);
  transition: 0.5s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transition-delay: 0.2s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn-l.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 0.2s 0.2s ease-in-out;
  }

  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.5s ease-in-out;
    transition-delay: 0.1s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.5s ease-in-out;
    transition-delay: 0.1s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn-l.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 0.5s ease-in-out;
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .container {
    padding: 1.5rem;
  }

  .container:before {
    bottom: 72%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>
